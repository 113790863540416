<script>
import { mapGetters } from "vuex";
import debounce from "debounce";

import pagination from "@/mixins/pagination";

import helperService from "@/services/helper";
import segmentService from "@/services/segment";
import treatmentSevice from "@/services/treatment";

import Spinner from "@/components/ui/Spinner";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "TreatmentList",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      treatments: [],
    };
  },

  computed: {
    ...mapGetters(["isDoctor"]),
  },

  created() {
    this.getTreatments();

    this.$bus.$on("treatment-update", (treatment) => {
      const index = this.treatments.findIndex((p) => p._id === treatment._id);
      if (index >= 0) {
        this.$set(this.treatments, index, treatment);
      } else {
        this.treatments.unshift(treatment);
      }
    });
  },

  methods: {
    getTreatments(query = {}) {
      this.isLoading = true;
      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };
      treatmentSevice
        .get(defaultQuery)
        .then((res) => {
          this.treatments = res.docs || [];
          this.pagination.total = res.total || 0;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceInput: debounce(function () {
      this.getTreatments(
        helperService.buildQuery({
          offset: 0,
          searchTreatment: this.searchInput,
          queryProperties: ["name"],
        })
      );
    }, DEBOUNCE_MS_TIMEOUT),

    editTreatment(id) {
      this.$router.push({ name: "update-treatment", params: { id } });
    },

    deleteTreatment(treatment) {
      this.$confirm(
        `¿Desea eliminar el protocolo: ${treatment.name}?`,
        "Eliminar",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          segmentService.track({ name: "Treatment Deleted" });
          return treatmentSevice.delete(treatment._id);
        })
        .then(() => this.getTreatments())
        .catch(() => {});
    },

    goToTreatmentTasks(id) {
      helperService.callIfNoSelectedText(() => {
        this.$router.push({
          name: "treatment-tasks",
          params: { id },
        });
      });
    },

    onPageChange() {
      this.getTreatments();
    },
  },
};
</script>

<template lang="pug">
  section
    header.headline
      .headline__title
        h1
          | Protocolos
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input(prefix-icon="el-icon-search" v-on:input="debounceInput" v-model="searchInput" placeholder="Buscar" :clearable="true")
        router-link.button.button--blue(:to="{name: 'create-treatment'}" v-auth="'treatment.create'")
          micon(name="add_circle_outline").button__icon
          span.button__text Nuevo Protocolo
    .box.box--with-subnav
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Descripción
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(treatment, index) in treatments" :key="treatment._id" @click="goToTreatmentTasks(treatment._id)")
              td {{ treatment.name }}
              td {{ treatment.description }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                        div(@click="editTreatment(treatment._id)" v-auth="'treatment.update'")
                          micon(name="edit")
                          | Editar Protocolo
                      el-dropdown-item
                        router-link(:to="{name: 'treatment-tasks', params: { id: treatment._id }}")
                          micon(name="note_add")
                          | Tareas asignadas
                      el-dropdown-item(v-auth="'treatment.remove'")
                        div(@click="deleteTreatment(treatment)")
                          micon(name="delete_forever")
                          | Eliminar Protocolo

      pagination(
        :isLoading="isLoading",
        :limit="pagination.limit",
        :total="pagination.total",
        @pagination="setPagination"
      )
</template>

<style lang="scss" scoped>
tbody td:hover {
  cursor: pointer;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('section',{staticClass:"detail"},[_c('spinner')],1):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"detail",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('header',{staticClass:"detail__header"},[_c('h2',{staticClass:"detail__headline"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.vitalConfig.name)))]),_c('div',{directives:[{name:"auth",rawName:"v-auth",value:('task-default.create'),expression:"'task-default.create'"}],staticClass:"detail__actions"},[(_vm.isUpdate)?_c('el-button',{attrs:{"type":"danger","disabled":_vm.vitalConfig.isNative},on:{"click":_vm.deleteVitalConfig}},[_vm._v("Borrar")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","native-type":"submit","loading":_vm.isPostingVitalConfig}},[_vm._v("Guardar")])],1)]),_c('p',{staticClass:"subtitle"},[_vm._v("Los campos con (*) son obligatorios")]),(_vm.isFetchingVitalConfig)?_c('div',{staticClass:"modal__content"},[_c('spinner')],1):_c('div',{staticClass:"detail__content"},[_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Nombre")]),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"autofocus":"","disabled":_vm.vitalConfig.isNative},model:{value:(_vm.vitalConfig.name),callback:function ($$v) {_vm.$set(_vm.vitalConfig, "name", $$v)},expression:"vitalConfig.name"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("Descripción")]),_c('el-input',{attrs:{"type":"textarea","rows":"3","maxlength":"250"},model:{value:(_vm.vitalConfig.description),callback:function ($$v) {_vm.$set(_vm.vitalConfig, "description", $$v)},expression:"vitalConfig.description"}})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.vitalConfig.measurement.green.min),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.green, "min", $$v)},expression:"vitalConfig.measurement.green.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Máximo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.vitalConfig.measurement.green.max),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.green, "max", $$v)},expression:"vitalConfig.measurement.green.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Mínima")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.vitalConfig.measurement.yellow.min),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.yellow, "min", $$v)},expression:"vitalConfig.measurement.yellow.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Máxima")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.vitalConfig.measurement.yellow.max),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.yellow, "max", $$v)},expression:"vitalConfig.measurement.yellow.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.vitalConfig.measurement.red.min),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.red, "min", $$v)},expression:"vitalConfig.measurement.red.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Máximo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.vitalConfig.measurement.red.max),callback:function ($$v) {_vm.$set(_vm.vitalConfig.measurement.red, "max", $$v)},expression:"vitalConfig.measurement.red.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }